import "./App.css";
import title from "./assets/title.png";
import heroLogo from "./assets/heroLogo.png";
import sajeetSelfie from "./assets/sajeet1.jpg";
import rodMep from "./assets/rodmep.png";
import Modal from "./Modal";
import React, { useState, useEffect } from "react";
import gif1 from "./assets/finalGIF.gif";
import gif2 from "./assets/finalGIF2.gif";
import mim1 from "./assets/mim1.jpeg";
import mim2 from "./assets/mim2.jpeg";
import mim3 from "./assets/mim3.jpeg";
import YouTube from "react-youtube";

const Hero = () => {
  const [daysUntil, setDaysUntil] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hoursUntil, setHoursUntil] = useState(0);
  const [minutesUntil, setMinutesUntil] = useState(0);
  const buyButtonPressed = () => {
    setIsOpen(true);
    console.log("pressed");
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const twitterPressed = () => {
    const url = "https://X.com/sanjeetonsol";
    window.open(url, "_blank");
  };

  const telegramPressed = () => {
    const url = "https://t.me/sanjeetportal";
    window.open(url, "_blank");
  };

  const YTPressed = () => {
    const url = "https://www.youtube.com/@SanjeetSol";
    window.open(url, "_blank");
  };

  useEffect(() => {
    const calculateTimeUntil = () => {
      const currentDate = new Date();
      const targetDate = new Date(currentDate.getFullYear(), 3, 25);

      if (targetDate < currentDate) {
        targetDate.setFullYear(targetDate.getFullYear() + 1);
      }

      const diffInTime = targetDate.getTime() - currentDate.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
      const diffInHours = Math.floor(
        (diffInTime % (1000 * 3600 * 24)) / (1000 * 3600)
      );
      const diffInMinutes = Math.floor(
        (diffInTime % (1000 * 3600)) / (1000 * 60)
      );

      setDaysUntil(diffInDays);
      setHoursUntil(diffInHours);
      setMinutesUntil(diffInMinutes);
    };

    calculateTimeUntil();

    const timer = setInterval(calculateTimeUntil, 60000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="hero">
      <Modal isOpen={isOpen} onClose={closeModal} />
      <img src={title} alt="logo" className="title" />
      <a>The jeet</a>
      <img src={heroLogo} alt="logo" className="heroLogo" />
      <div className="buttonSection">
        <div className="launch-con">
          <div className="launch-container">
            <a className="launch">
              {daysUntil} Days {hoursUntil} hours and {minutesUntil} minutes
              left Until Launch
            </a>
          </div>
        </div>
        <button className="buyButton" onClick={buyButtonPressed}>
          Buy $anjeet
        </button>
        <div>
          <a className="caption">(Buy button will be activated on launch)</a>
        </div>
        <button className="twitterButton" onClick={twitterPressed}>
          Twitter
        </button>
        <button className="telegramButton" onClick={telegramPressed}>
          Telegram
        </button>
        <button className="YTButton" onClick={YTPressed}>
          Youtube
        </button>
        <button className="chartsButton" onClick={buyButtonPressed}>
          Charts
        </button>

        <div className="divid">
          <a className="caption">(Charts button will be activated on launch)</a>
        </div>
        <div>
          <a className="contact-address">
            Contact address: (will be revelead on launch)
          </a>
        </div>
      </div>
    </div>
  );
};

const Welcum = () => {
  return (
    <div className="welcum-container">
      <a className="welcum-title">Welcum 2 mi websit</a>
      <a className="welcum-caption">This me:</a>
      <img src={sajeetSelfie} className="selfie" />
    </div>
  );
};

const About = () => {
  return (
    <div className="about-container">
      <a className="about-title">Abowt</a>
      <a className="about-text">
        Hi sarr mi Sanjeet. I cum 2 u as frend. Neybor. Felow hoomon. I wont u
        suport mi jurney. I tred cyrpto to feed village and bey pump for water.
        I mek onest monies. I help famili, pepel, shariti, 100 parsent gud
        person. Pretty girl in USA to marri for Visa, pls buy so I sell.
      </a>
    </div>
  );
};

// const RoadMap = () => {
//   return (
//     <div className="roadmap-container">
//       <a className="welcum-title">Rod Mep</a>
//       <div className="map-container">
//         <div>
//           <img src={rodMep} className="rodmep-image" />
//         </div>
//         <div>
//           <div className="divider">
//             <a className="rodmep-item">1. </a>
//           </div>
//           <div className="divider">
//             <a className="rodmep-item">
//               2. blah blah blah blah blah blah blah blah blah blah blah blah
//               blah blah blah blah blah blah blah blah
//             </a>
//           </div>
//           <a className="rodmep-item">
//             3. blah blah blah blah blah blah blah blah blah blah blah blah blah
//             blah blah blah blah blah blah blah
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

const HowBuy = () => {
  return (
    <div className="about-container">
      <a className="about-title">How Buy</a>
      <a className="about-text">I onli sell, so u tel mi</a>
      <img src={mim2} className="selfie" />
    </div>
  );
};

const Memes = () => {
  return (
    <div className="memes-container">
      <a className="welcum-title">Mims</a>
      <div className="video-container">
        <div>
          <a className="welcum-caption">Video of mi Holy Wood awdishun</a>
          <YouTube videoId="b1rHS_JS4Kk" />
        </div>
      </div>
      <div className="memes-table">
        <img src={sajeetSelfie} className="mims" />
        <img src={gif1} className="mims" />
        <img src={gif2} className="mims" />
        <img src={mim1} className="mims" />
        <img src={mim3} className="mims" />
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-text">© 2024 $anjeet</p>
    </footer>
  );
};

function App() {
  return (
    <div className="App">
      <Hero />
      <Welcum />
      <About />
      <Memes />
      <HowBuy />
      <Footer />
    </div>
  );
}

export default App;
